export const SET_SELECTED_VIDEO_STATE = 'SET_SELECTED_VIDEO_STATE';
export const setSelectedVideoState = data => ({
	type: SET_SELECTED_VIDEO_STATE,
	payload: data,
});

export const RESET_SELECTED_VIDEO_STATE = 'RESET_SELECTED_VIDEO_STATE';
export const resetSelectedVideoState = data => ({
	type: RESET_SELECTED_VIDEO_STATE,
	payload: data,
});