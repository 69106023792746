import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMoralis } from 'react-moralis';

import consoleStartup from '../helpers/consoleStartup';
import Routes from './routes/routes';

import { setWalletState } from '../actions/walletActions';
import { setHolderState } from '../actions/holderActions';

function App() {
  const { isWeb3Enabled, isAuthenticated, user, enableWeb3 } = useMoralis();
  const dispatch = useDispatch();
  const walletState = useSelector(state => state.wallet);
  const holderState = useSelector(state => state.holder);

  useEffect(() => {
    consoleStartup();
  }, [])

  useEffect(() => {
    if (!isWeb3Enabled && isAuthenticated) enableWeb3();
    if (isWeb3Enabled && isAuthenticated) {
      dispatch(setWalletState({
        ...walletState,
        isConnected: true,
        address: user?.attributes.ethAddress,
        user: user?.attributes
      }));
      if (user?.attributes.ethAddress){
        dispatch(setHolderState({
          ...holderState,
          isLoading: true
        }));
        fetch(`https://sdc-api-svc.azurewebsites.net/is-holder/${user?.attributes.ethAddress}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then(data => {
          dispatch(setHolderState({
            ...holderState,
            ...data,
            isLoading: false
          }));
        })
        .catch(err => {
          dispatch(setHolderState({
            ...holderState,
            isLoading: false
          }));
          console.error("Error fetching data: ", err);
        });
      }
      return;
    }
  }, [isWeb3Enabled, isAuthenticated, enableWeb3])

  return <Routes />;
}

export default App;
