import { 
	SET_MENU_STATE,
	TOGGLE_MENU_STATE
} from '../actions/menuActions';

const initialState = {
	isMenuOpen: false
};

export default function menuReducer (
		state = { ...initialState }, 
		action
) {
	const nextState = { ...state };

	switch (action.type) {
    	case SET_MENU_STATE:
      	return {
					...nextState,
					isMenuOpen: action.isMenuOpen
      	};
			case TOGGLE_MENU_STATE:
      	return {
					...nextState,
					isMenuOpen: !state.isMenuOpen
      	};
    	default: return {
				...state,
			};
  	}
};