export const SET_MENU_STATE = 'SET_MENU_STATE';
export const setMenuState = data => ({
	type: SET_MENU_STATE,
	payload: data,
});

export const TOGGLE_MENU_STATE = 'TOGGLE_MENU_STATE';
export const toggleMenuState = data => ({
	type: TOGGLE_MENU_STATE,
	payload: data,
});
