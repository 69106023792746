import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import menuReducer from './menuReducer'
import walletReducer from './walletReducer'
import holderReducer from './holderReducer';
import selectedVideoReducer from './selectedVideoReducer';
  
export default function reducer(history){
  return combineReducers({
    router: connectRouter(history),
    menu: menuReducer,
    wallet: walletReducer,
    holder: holderReducer,
    selectedVideo: selectedVideoReducer
  });
};
  