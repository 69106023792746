export const SET_WALLET_STATE = 'SET_WALLET_STATE';
export const setWalletState = data => ({
	type: SET_WALLET_STATE,
	payload: data,
});

export const RESET_WALLET_STATE = 'RESET_WALLET_STATE';
export const resetWalletState = data => ({
	type: RESET_WALLET_STATE,
	payload: data,
});
