import React from 'react';
import './style/main.css'
import ReduxProvider from './reduxProvider';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { DAppProvider } from '@usedapp/core';
import { MoralisProvider } from 'react-moralis';

import settings from '../../settings';

const AppConfiguration = ({ children }) => (
    <ReduxProvider>
        <BrowserRouter>
            <ToastProvider 
                placement="bottom-right"
            >
                <MoralisProvider appId={settings.moralis.appId} serverUrl={settings.moralis.serverUrl}>
                    <DAppProvider config={{}}>
                        {children}
                    </DAppProvider>
                </MoralisProvider>
            </ToastProvider>
        </BrowserRouter>
    </ReduxProvider>
);

export default AppConfiguration;
