import { 
	SET_WALLET_STATE,
	RESET_WALLET_STATE
} from '../actions/walletActions';

const initialState = {
	isConnected: false
};

export default function walletReducer (
		state = { ...initialState }, 
		action
) {
	const nextState = { ...state };

	switch (action.type) {
    	case SET_WALLET_STATE:
      	return {
					...nextState,
					...action.payload
      	};
			case RESET_WALLET_STATE:
      	return {
					...initialState
      	};
    	default: return {
				...state,
			};
  	}
};