export const SET_HOLDER_STATE = 'SET_HOLDER_STATE';
export const setHolderState = data => ({
	type: SET_HOLDER_STATE,
	payload: data,
});

export const RESET_HOLDER_STATE = 'RESET_HOLDER_STATE';
export const resetHolderState = data => ({
	type: RESET_HOLDER_STATE,
	payload: data,
});
