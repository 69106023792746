import { 
	SET_SELECTED_VIDEO_STATE,
	RESET_SELECTED_VIDEO_STATE
} from '../actions/selectedVideoActions';

const initialState = { 
  url: '',
	videoDescription: '',
	title: '',
	loop: false, 
	controls: true, 
	light: false, 
  muted: false, 
	width: "1150px",
	height: "720px",
	pip: true,
	stopOnUnmount: true
}

export default function selectedVideoReducer (
		state = { ...initialState }, 
		action
) {
	const nextState = { ...state };

	switch (action.type) {
    	case SET_SELECTED_VIDEO_STATE:
      	return {
					...nextState,
					...action.payload
      	};
			case RESET_SELECTED_VIDEO_STATE:
      	return {
					...initialState
      	};
    	default: return {
				...state,
			};
  	}
};