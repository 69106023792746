import { 
	SET_HOLDER_STATE,
	RESET_HOLDER_STATE
} from '../actions/holderActions';

const initialState = {
	isHolder: false,
	isLoading: false,
	count: 0,
	tokens: []
};

export default function holderReducer (
		state = { ...initialState }, 
		action
) {
	const nextState = { ...state };

	switch (action.type) {
    	case SET_HOLDER_STATE:
      	return {
					...nextState,
					...action.payload
      	};
			case RESET_HOLDER_STATE:
      	return {
					...initialState
      	};
    	default: return {
				...state,
			};
  	}
};