import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router';
//import IsLoadingView from '../UI/isLoadingView/isLoadingView';

export const RedirectLoading = React.lazy(() => import('../RedirectLoading/RedirectLoading'));
export const AuthenticationFailed = React.lazy(() => import('../authenticationFailed/authenticationFailed'));
export const LogOut = React.lazy(() => import('../LogOut/LogOut'));


export const Home = React.lazy(() => import('../Pages/Home/Home'));
export const Videos = React.lazy(() => import('../Pages/Videos/Videos'));
export const View = React.lazy(() => import('../Pages/View/View'));
export const Merch = React.lazy(() => import('../Pages/Merch/Merch'));
export const Whitelist = React.lazy(() => import('../Pages/Whitelist/Whitelist'));
export const NotFoundPage = React.lazy(() => import('../Pages/404/404'));

export default function AppRoutes(){ 
    return (
        <Suspense fallback={<div></div>}>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/videos" element={<Videos title="Videos" tiles={[{
                    articleStyle: "style2",
                    image: "image2",
                    link: "/watch/mx0ekn",
                    title: "Frederick Vesti Q&A",
                    text: "QnA"
                }, {  
                    articleStyle: "style3",
                    image: "image3",
                    link: "/watch/4ryipj",
                    title: "Juan MC Q&A",
                    text: "QnA"
                }, {  
                    articleStyle: "style4",
                    image: "image4",
                    link: "/watch/l30xnd",
                    title: "Lee RedBull Mechanic Q&A",
                    text: "QnA"
                }]} />} />
                <Route exact path="/watch/:videoId" element={<View />} />
                <Route exact path="/merch" element={<Merch />} />
                <Route exact path="/whitelist" element={<Whitelist />} />
                <Route exact path="/404" element={<NotFoundPage/>} />
                <Route path="*" element={<NotFoundPage/>} />
            </Routes>
        </Suspense>
    );
}