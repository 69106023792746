export default function consoleStartup(params) {
  const asciiArt = [
    "",
    " .d8888b.  888                       888",
    "d88P  Y88b 888                       888",
    "Y88b.      888                       888",
    ' "Y888b.   888888  .d88b.  88888b.   888',
    '    "Y88b. 888    d88""88b 888 "88b  888',
    '      "888 888    888  888 888  888  Y8P',
    "Y88b  d88P Y88b.  Y88..88P 888 d88P",
    ' "Y8888P"   "Y888  "Y88P"  88888P"   888',
    "                           888",
    "                           888",
    "                           888"
  ];
  const sideText = 'This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or "hack" someone\'s account, it is a scam and will give them access to your account.';
  const bottomText = "See this FAQ by facebook https://www.facebook.com/selfxss for more information.";
  const split = ("" + sideText.toString()).match(/.{35}.+?\s+|.+$/g);
  if (split != null) {
      const startingRow = Math.floor(Math.max(0, (asciiArt.length - split.length) / 2));
      for (let i = 0; i < asciiArt.length || i < split.length; i++) {
            asciiArt[i] += new Array(45 - asciiArt[i].length).join(" ") + (split[i - startingRow] || "");
      }
  }
  console.log("\n\n\n" + asciiArt.join("\n") + "\n\n" + bottomText.toString() + "\n");
  console.log(`((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((((((((#((#(#((((((((((((((((((((((((((((((((((((((((((
((((((((((((((((((((((((((######(######(((#((#(((#((((((((((((((((((((((((((((((
(((((((((((((((((((#############(, ........%@@(*#*/(#(((((((((((((((((((((((((((
((((((((((((((((###########*.(@@@@,..*&&%#*,.......(@@@#*#((((((((((((((((((((((
(((((((((#############(#(.,.,*/.,(.,,,...,&@(/&@#.....,&@@(#((((((((((((((((((((
(((((((((##############(@&,.,,,....,,,,,.,,,..&&.,......,.(*#(#(((((((((((((((((
(((((((#(#############..,/,,,./&..,,,.,.,,&*,.,.,(,,...,..*(*##(###(((((((((((((
((((((###############****..../@@@,..,,,,*@@@(..,.,,,,...*@@@@/#######(((((((((((
((((################%,**@@&......,,*..,.,,..,,,,,..,..,,.#,.#,.########(((((((((
(((#################(,*#@@@@@*..,,/,,*......,,,,*@@@@@(,......./*##(##((((((((((
(###################/**#@@@@@@@(..,,***,.........,#(@/,...........####((((((((((
####################(**/@@@@@@@//*,***/***/**,......,.,,.,,,.,..,(######((((((((
####################%,*,&@@*&@@*/*/#,********/*/////////(///(#(/,(########((((((
#####################*,*/@%(((@(,&#@(*,.******///(//////////((/**#########((((((
####################%%*,,%/@@/@@#,@@****@@@@@#,,///////////(((//##########((((((
#####################%##.,/&@@(*/&@%***#@@@@@@@***/*****(@@&*%###########(((((((
(######################%%%./%%*,*/****(@@@@@@@#***,.... ,(&#/######/*,,,**/#((((
(######################%%%%.   .,,,***@@@@@@@%*********@@%(,,,,,,,,,,,.,#/,/((((
##########################%%%%%*      ,%@@@@/*******,@@@%,################((((((
#########################%%%%%%* .%&%%#       ,***#@@&*##################(((((((
((#####################%%#/,../ ,%%%#    .#/*%%#%#*./###################((((((((
(((################/./*(/**.*,,(#&    ,%%%##***.*#%####################(((((((((
((((############(,,*(**,.*/,....*(. /@&%#%,.,(**//,.**/%#############(((((((((((
((((((##########,./,**,////(,...// (%%#@%*(##/...*,(#,...,###########(((((((((((
(((((##(####(....,/*((.,/*.,(#(,., %%&@%/((((/((/...(((#*.,.#####(((((((((((((((
(((((((((((../(((((/.,,.,,,*(((/(/,(%%%,.**.,,,*(///((,.*.,,/##(((((((((((((((((
(((((((((/.,,((/,../(((#(,..,*(*.*.(%%#/#**,..,///,. *,.*.*//(#(((((((((((((((((
((((((((#***..*..,(/.,(/.....((,...(%%/,*,,(*...,/,..,,(##(...#(((((((((((((((((
((((((((/,....**,..,(,,/,*//,./**#(/%%*...**....,...*(/./#.,**./#(((((((((((((((
((((((((*,.//.****,.*(((,.,,,(#..,.*%%*.(/,*..,.,*/*,,((*(***,..((((((((((((((((
`)
}